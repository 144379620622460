import React, { Fragment, useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineEditCalendar, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaTrash, FaHeart, FaRegHeart  } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { MdOutlinePersonOff } from "react-icons/md";
import { Service } from '../../services/api';
import Renderif from '../../utils/renderif';
import { GlobalContext } from '../../context/global_context';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '../../context/notification_context';
import { AuthContext } from '../../context/auth_context';
import NuLoading from '../../components/nu_loading';
import { LuBadgeCheck } from "react-icons/lu";
import { HiBadgeCheck } from "react-icons/hi";
import { RxAvatar } from "react-icons/rx";
import NuSelect from '../../components/nu_select';
import { alphaOrder, restrictionReasons, timeOfDay, UTCDateParser } from '../../utils';
import { BiFilter, BiSearch, BiTrash } from 'react-icons/bi';
import { BsPersonSlash, BsThreeDotsVertical } from 'react-icons/bs';
import { darkBlue } from '../../styles/colors';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoCloseCircle } from 'react-icons/io5';
import { FaCircleArrowUp, FaCircleChevronDown, FaCircleChevronUp } from "react-icons/fa6";
import { CiMedicalCross } from 'react-icons/ci';
import { LiaClinicMedicalSolid } from "react-icons/lia";
import CheckBox from '../../components/nu_checkbox';

export default function SolicitudesClinicaView() {

    const {user_data, user} = useContext(AuthContext)
    const {getUserType, center, centerType,assistanceUnit, getAssistanceUnit, getProfessionalField, loadCenters} = useContext(GlobalContext)


    useContext(GlobalContext);
    const {notify} = useContext(NotificationContext)
    const navigate = useNavigate()
    const [state, setState] = useState({
        filters:'',
        page:1,
        count:7,
        professionals:[],
        professionalsCount:0, 
        bloqueos:[],
        loading:false,
        show_validate:false,
        profesiona_selected:null,
        centers:[],
        // center_selected:'',
        // assistenceunit_selected:'',
        // centertype_selected:'',
        search:'',
        solicitudes:[],
        solicitudes_open:[],
        solicitud_selected:[],
        show_approving_acogida:false,
        show_approving_sin_acogida:false,
        show_reason_deny:false,
        reason_deny:'',
        modalToBlock:false,
        reasons:[],
        centers:[],

    })
    const [shiftCounts, setShiftCounts] = useState({});


    useEffect(()=>{

        init();
        return () => {}
    },[])

    const init = async () => {
      
        setState({...state, loading:true})

        console.log(user_data);
        console.log(user);

        let _solicitudes = await Service('GET', `solicitud/${user_data?.id}`)
        let _center = await Service('GET', `center/${user_data?.id}`)
        console.log(_center);
        state.centers = _center.data.list
        // state.organization = _organization.data.organizationData;
        // let _centers = await loadCenters()
        // state.centers = _centers.filter((center) => center.idOrganization === _organization.data.organizationData.id)
     
        console.log(_solicitudes);
        const result = Object.groupBy(_solicitudes.data.list, (item) => item.idAssistanceUnit);
  
        console.log(result);

        let request = []
        for (const [key, value] of Object.entries(result)) {
            const resultUser = Object.groupBy(value, (item) => item.idUser);
        
            for (const [_key, _value] of Object.entries(resultUser)) {
                const resultCenter = Object.groupBy(_value, (item) => item.idCenter);
                
                
                for (const [__key, __value] of Object.entries(resultCenter)) {
                    
                    
                    
                    request.push(__value)
                   
                }
               
            }
        }
        state.solicitudes = request;
     

        setState({...state, loading:false})



        // let _profesionals = await loadProfessionals({page:state.page, count:state.count, status:state.filters})
        // console.log(_profesionals)
        // state.professionals = _profesionals.data.list
        // state.professionalsCount = _profesionals.data.total
        // setState({...state, loading:false})
    }

    const loadProfessionals = async ({page, count, search = ''}) => {
        let query = `user/getMyProfessionals?page=${page}&pageCount=${count}`
        if(search !== ''){
            query = query+'&term='+search
        }
        let _profesionals = await Service('GET', query)

        console.log(_profesionals)
        let _bloqueos = await Service('GET', 'restriction/getAll');

        let my_centers = center.filter((item)=>item.idOrganization === user_data?.id);

        state.centers = my_centers

        let bloqueos = []

        for (let i = 0; i < my_centers.length; i++) {
            console.log(my_centers[i])
            _bloqueos.data.list.map((bloqueo)=>{
                if(bloqueo.idCenter === my_centers[i].id){
                    bloqueos.push(bloqueo)
                }
            })
        }
        

        console.log(bloqueos)
        state.bloqueos = bloqueos;


        // console.log(_bloqueos);
        // console.log(_profesionals);
        return _profesionals;

    }

    const onBlockProfesional = async () => {
   
  
        
        if(!state.reasons.length) {
          notify({title:'¡Error!', message:'Por favor selecciona al menos un motivo.', type:'error'})
          return
        }
        setState({...state, loading:true})
        const reasonsBlock = state.reasons.sort().map((item) => restrictionReasons[item])

        for (let i = 0; i < state.centers.length; i++) {
                
            let body = {
                type:'0',
                idCenter:state.centers[i].id,
                idUser:state.solicitud_selected[0].idUser,
                reason: JSON.stringify(reasonsBlock),
            }
            
            let _block = await Service('POST', 'restriction/create', body);
            console.log(_block)
      
            if(_block.status === 201){
              notify({title:'¡Listo!', message:`Profesional bloqueado exitosamente en el centro ${center.name}.`, type:'success'})
             
            }else{
              if(_block.response.status === 400){
                notify({title:'Profesional bloqueado!', message:`Ya has bloqueado este profesional en el centro ${center.name}.`, type:'error'})  
              }else{
                notify({title:'Error!', message:`Ocurrio un error al itentar bloquear profesional en el centro ${center.name}.`, type:'error'})
              }
            }
        }

   

        state.reasons = []
        state.modalToBlock = false;

        // let _solicitudes_user = state.solicitudes.filter((solicitud)=>solicitud.idUser === state.solicitud_selected[0].idUser)
        
        for (let i = 0; i < state.solicitudes.length; i++) {
            console.log(state.solicitudes[i])
            if(state.solicitudes[i][0].idUser === state.solicitud_selected[0].idUser){

                let _body = {
                    idCenter:state.solicitudes[i][0]?.idCenter,
                    idUser:state.solicitudes[i][0]?.idUser,
                    idAssistanceUnit:state.solicitudes[i][0]?.idAssistanceUnit,
                    reason: 'Bloqueo'
                }
                console.log(_body)
                let _deny = await Service('POST', 'solicitud/deny', _body)
                console.log(_deny)

            }
         
        }
       init();
      
    }

    const onUnblockProfesional = async (idUser) => {
        let _bloqueo = state.bloqueos.filter((bloqueo) => bloqueo.idUser === idUser)[0]
        let _unblock = await Service('DELETE', 'restriction/delete/'+_bloqueo.id);
        if(_unblock.status === 200){
            notify({title:'¡Listo!', message:'Profesional desbloqueado', type:'success'})
            init();
        }else{
            notify({title:'Erorr!', message:'No se ha podiado desbloquear este profesional.', type:'error'})
        }
    }

    const onFavoriteProfessional = async (idUser) => {
        setState({...state, loading:true})
        let _favorite = await Service ('POST', `favorite/create/${idUser}`)
        
        if(_favorite.status === 200){
            notify({title:'¡Listo!', message:'Profesional marcado como favorito', type:'success'})
        
            init();

            
        }else{
            notify({title:'Erorr!', message:'No se ha podiado marcar como favorito a este profesional.', type:'error'})
            setState({...state, loading:false})
        }
    }

    
    const onUnfavoriteProfessional = async (idUser) => {
        setState({...state, loading:true})
        let _unfavorite = await Service ('DELETE', `favorite/delete/${idUser}`)
        if(_unfavorite.status === 200){
            notify({title:'¡Listo!', message:'Profesional marcado como no favorito', type:'success'})

          
            init();


        }else{
            notify({title:'Erorr!', message:'No se ha podiado marcar como no favorito a este profesional.', type:'error'})
            setState({...state, loading:false})
        }
    }

   

    const renderPagination = () => {

        let pages = Math.ceil(state.professionalsCount / state.count);
        let items = []
        for (let i = 1; i <= pages; i++) {
            items.push(i);
        }

        return (
            <div>
               <span className='bold'>{state.page}</span> de <span className='bold'>{items.length}</span>
            </div>
        )


        // let pages = Math.ceil(state.professionalsCount / state.count);
        // let items = []
        // for (let i = 1; i <= pages; i++) {
        //     items.push(i);
        // }

        // // items.reverse()

        // return items.map((item, i) => {
           
        //     return (
        //         <div key={i} style={{width:32}} className={`f-16 bold text-dark px-2 cursor-pointer mb-1 r-10 text-center ${state.page === item ? 'bg-light':'' }`} onClick={async ()=>{
                    
        //             setState({...state, loading:true})
        //             let _profesionals = await loadProfessionals({page:item, count:state.count, status:state.filters})

          
        
                    
        //             setState({...state, 
        //                 page:item,
        //                 loading:false, 
        //                 professionals:_profesionals.data.list, 
        //                 professionalsCount:_profesionals.data.total,
                
        //             })
        //         }}>{item}</div>
        //     )
        // })
        
        


     

    }

    const userBlocked = (user) => {

        let blocked = false;
        state.bloqueos.map((bloqueo)=>{
        
            if(bloqueo.idUser === user.id){
           
                blocked = true;
       
            }
        })
        console.log(blocked)
        return blocked;
    }
    const loadPage = async (page) => {
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:state.page + page, count:state.count,  search:state.search})
        state.page = state.page + page
        setState({...state, 
            // page:state.page + page,
            loading:false, 
            professionals:_profesionals.data.list, 
            professionalsCount:_profesionals.data.total,
    
        })
        //  save_state()
    }

    const onSearch = async () => {
       
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:1, count:state.count, search:state.search })
        console.log(_profesionals)
        state.professionals = _profesionals.data.list
        state.professionalsCount = _profesionals.data.total
        state.page = 1
        setState({...state, loading:false})
        // save_state()
        
    }

    const onApproveWithOutReception = async (values) => {
        setState({...state, loading:true})

        let body = {}
        body = {
            idCenter:parseInt(state.solicitud_selected[0]?.idCenter),
            idUser:parseInt(state.solicitud_selected[0]?.idUser),
            // idAssistanceUnit:parseInt(state.solicitud_selected[0]?.idAssistanceUnit)
        }
        if(state.solicitud_selected[0]?.idAssistanceUnit !== null){
            body.idAssistanceUnit = parseInt(state.solicitud_selected[0]?.idAssistanceUnit)
        }
        let _completeReception = await Service('POST', 'completedReception/create',body)
        let _approve = await Service('POST', 'solicitud/approve', body)
        
        if(_completeReception.status === 200 &&  _approve.status === 200){
            notify({title:'¡Listo!', message:'La solicitud ha sido aprovada.', type:'success'})
            state.show_approving_sin_acogida = false;
            
            // receptions(true);
            // setState({...state, modalToAccept:false, modalConfirm:true})
            // return;
        }
        init();
        // setState({...state, loading:false})
    }

    const onDenyRequest = async () => {

        setState({...state, loading:true})

        let body = {}
        body = {
            idCenter:parseInt(state.solicitud_selected[0]?.idCenter),
            idUser:parseInt(state.solicitud_selected[0]?.idUser),
            idAssistanceUnit:parseInt(state.solicitud_selected[0]?.idAssistanceUnit),
            reason: state.reason_deny
        }
        // if(values.centertype_selected === '2'){
        //     body.idAssistanceUnit = parseInt(state.assistenceunit_selected)
        // }
   
        let _deny = await Service('POST', 'solicitud/deny', body)
        
        if(_deny.status === 200){
            notify({title:'¡Listo!', message:'La solicitud ha sido denegada.', type:'success'})
            state.show_reason_deny = false;
            state.modalToBlock = false;
            
            // receptions(true);
            // setState({...state, modalToAccept:false, modalConfirm:true})
            //return;
        }
        init();

    }

    useEffect(() => {
        const fetchShiftCountsForItem = async (item) => {
            try {
                let _count = await Service('GET', `shift/getAll/?idUser=${item.id}&idOrganization=${user_data.id}`)
                console.log('---> ', _count.data.list.length)
                setShiftCounts(prevCounts => ({
                    ...prevCounts,
                    [item.id]: _count?.data?.list?.length || 0
                }))
            } catch (error) {
                console.error("Error fetching shift count for item: ", error)
                setShiftCounts(prevCounts => ({
                    ...prevCounts,
                    [item.id]: 0
                }))
            }
        }

        state.professionals?.forEach(item => {
            fetchShiftCountsForItem(item)
        })
    }, [state.professionals])
   

    


  



  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <ClinicSideMenu page='solicitudes' />
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

            <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            {/* <div className='f-16 text-white'>5.0 (0 reseñas)</div> */}
                            <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container-fluid container-xl col mx-auto p-3'>


                <div className='f-24  bold mt-2'>Solicitudes</div>


                

               
                    <div className="d-flex mt-2 w-100 ">
                        <div className="bg-gray w-100 r-10 mt-2 overflow-auto hide-scroll">
                        
                        <table className='w-100'>
                            <thead className="p-3">
                            <th className="p-2 f-14 ">Profesional</th>
                            <th className="p-2 f-14 ">Turnos solicitados</th>
                            <th className="p-2 f-14 ">Centro</th>
                            <th className="p-2 f-14 ">Unidad asistencial</th>
                    
                            <th className="p-2 f-14 "></th>
                            
                            
                            </thead>
                        
                            <tbody className="bg-light">

                                {
                                    state.solicitudes.map((item, i)=>{
                                
                                        return (
                                            <Fragment>
                                                <tr key={i} className="b-b b-t">
                                                    <td className='p-2 '>
                                                        <div className='d-flex  align-items-start'>
                                                            <div className='nu-icon nu-icon--mid'>
                                                                <RxAvatar color='#0D78BF' size={30}></RxAvatar>
                                                            </div>
                                                            

                                                            <div className='ps-2'>
                                                                <Renderif isTrue={item[0]?.user?.name === undefined}>
                                                                    <div className='f-14 bold'>Sin nombre</div>
                                                                </Renderif>
                                                                <div className='f-14 bold cursor-pointer text-darkblue' onClick={()=>{
                                                                    navigate('/clinic/profesionales/'+item[0]?.idUser)
                                                                }}>{item[0]?.user?.name}</div>
                                                                <div className='f-12'>{getUserType(item[0]?.user?.idUserType.toString())}</div>
                                                                
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='p-2'  >
                                                        <div className='d-flex align-items-center'>
                                                            <div className='nu-icon nu-icon--mid'>
                                                            <div className='f-16 bold'>{item?.length}</div>
                                                            </div>
                                                            <div className='nu-icon nu-icon--mid cursor-pointer ms-2' onClick={()=>{
                                                                if(state.solicitudes_open.includes(item)){
                                                                    state.solicitudes_open = state.solicitudes_open.filter((el) => el !== item)
                                                                }else{
                                                                    state.solicitudes_open.push(item)
                                                                }
                                                                setState({...state})
                                                            }}>
                                                                <Renderif isTrue={!state.solicitudes_open.includes(item) }>
                                                                    <FaCircleChevronDown size={25} color={'#CCC'}></FaCircleChevronDown>
                                                                </Renderif>
                                                                <Renderif isTrue={state.solicitudes_open.includes(item) }>
                                                                    <FaCircleChevronUp size={25} color={'#16B2DE'}></FaCircleChevronUp>
                                                                </Renderif>
                                                            
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='p-2' >
                                                        <div className='f-14 bold'>{item[0]?.center?.name}</div>
                                                    </td>
                                                    <td className='p-2' >
                                                        <div className='f-14 bold'>{item[0]?.assistanceUnit?.description}</div>
                                                    </td>
                                                    <td className='p-2'>
                                                        <div className='d-flex '>

                                                            <div className='col'></div>

                                                            {/* <div className={`nu_btn nu_btn--primary me-2`} onClick={()=>{

                                                                setState({...state, solicitud_selected:item, approving:true})
                                                            }}>

                                                                <div className='nu-icon nu-icon--mid me-1'>
                                                                    <HiBadgeCheck size={18}></HiBadgeCheck>
                                                                </div>

                                                                <div className='nu_btn-text '>Aprobar solicitud</div>
                                                            
                                                            </div> */}

                                                            <div>
                                                            <NuDropDown onSelect={(value)=>{
                                                                console.log(value)
                                                                switch (value) {
                                                                    case 0:
                                                                        setState({...state, solicitud_selected:item, show_approving_acogida:true})
                                                                        break;
                                                                
                                                                    case 1:
                                                                        setState({...state, solicitud_selected:item, show_approving_sin_acogida:true})
                                                                        break;
                                                                    
                                                                    default:
                                                                        break;
                                                                }
                                                            }}></NuDropDown>
                                                            </div>

                                                            <div className={`nu_btn me-2`} onClick={()=>{
                                                                setState({...state, solicitud_selected:item, show_reason_deny:true})
                                                            }}>

                                                                <div className='nu-icon nu-icon--mid me-1'>
                                                                    <HiBadgeCheck size={18}></HiBadgeCheck>
                                                                </div>

                                                                <div className='nu_btn-text '>Denegar</div>
                                                            </div>

                                                            <div className={`nu_btn nu_btn--danger`} onClick={()=>{
                                                                setState({...state, modalToBlock:true, solicitud_selected:item})
                                                            }}>

                                                            <div className='nu-icon nu-icon--mid me-1'>
                                                                <BsPersonSlash size={18}></BsPersonSlash>
                                                            </div>
                                                                <div className='nu_btn-text '>Bloquear profesional</div>
                                                            </div>

                                                        </div>
                                                    

                                                    </td>
                                                </tr>
                                                <Renderif isTrue={state.solicitudes_open.includes(item)}>
                                                    {item?.map((item, i)=>{
                                                        
                                                        return (
                                                            <tr className='bg-white' key={i}>
                                                                <td colSpan={1}></td>
                                                                <td colSpan={5} className=''>
                                                                    <div className='bg-skyblue shadow-sm py-2 px-1 my-1 me-2 r-8 d-flex flex-row align-items-center'>

                                                                        {/* <div className='d-flex align-items-center  pe-3 col-3 '>
                                                                            <div className='nu-icon nu-icon--mid'>
                                                                            <LiaClinicMedicalSolid color='#0D78BF'></LiaClinicMedicalSolid>
                                                                            </div>
                                                                            <div className='f-12 bold'>{item?.center?.name}</div>


                                                                        </div> */}

                                                                        <div className='d-flex align-items-center pe-3 col-3 '>
                                                                                <div className='nu-icon nu-icon--mid'>
                                                                                    <img width="63%" src='/assets/calendar_solid.svg'></img>
                                                                                </div>
                                                                            
                                                                                
                                                                                <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(item?.shift?.date).toUTCString(), true)}</div>
                                                                            
                                                                        </div>

                                                                        <div className='d-flex align-items-center  pe-3'>
                                                                            <div className='nu-icon nu-icon--mid'>
                                                                                <img width="70%" src='/assets/clock_solid.svg'></img>
                                                                            </div>
                                                                            <div className='f-12 bold ellipsis'>{item?.shift?.initialHour} - {item?.shift?.finalHour}</div>
                                                                        </div>

                                                                        <div className='d-flex align-items-center  pe-3 '>
                                                                            <div className='nu-icon nu-icon--mid'>
                                                                                <MdOutlineEditCalendar color='#0D78BF'></MdOutlineEditCalendar>
                                                                            </div>
                                                                            <div className='f-12 bold ellipsis'>Cobertura</div>
                                                                        </div>

                                                                        <div className='d-flex align-items-center  pe-3 col '>
                                                                            <div className='nu-icon nu-icon--mid'>
                                                                                <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                                                            </div>
                                                                            <div className='f-12 bold ellipsis'>{getProfessionalField(item?.shift?.idProfessionalField)}</div>
                                                                        </div>

                                                                    
                                                                        <div className='col'></div>
                                                                        {/* <Renderif isTrue={i === 0}>
                                                                            <div className={`nu_btn nu_btn--primary me-2 px-3`} onClick={()=>{

                                                                                // setState({...state, solicirudes_open:item, approving:true})
                                                                            }}>

                                                                        

                                                                                <div className='nu_btn-text '>Convertir en acogida</div>
                                                                            </div>
                                                                        </Renderif> */}

                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        )
                                                    })}
                                                    
                                                </Renderif>
                                            </Fragment>
                                        )
                                    })
                                }
                                <tr className='my-5 bg-white'>
                                    <td colSpan={5}>
                                        <div className='my-5'></div>
                                    </td>
                                </tr>
                        
                            </tbody>
                        </table>
                        </div>
                    </div>
            
                
                <Renderif isTrue={state.solicitudes.length === 0 && state.loading === false}>
                    <div id='' className='w-100 bg-gray-00 r-10 py-5 px-5 d-flex'>
                        <div className='col px-3'>
                            <div className='bold f-14 text-white bg-darkblue p-2 text-center r-10 mt-5 px-5 position-relative'>
                                <div className='bubble-arrow'></div>
                                No tienes solicitudes pendientes por aprobar, te informaremos cuando un profesional solicite uno de tus turnos publicados o bien, quiera realizar una Acogida en tu Centro.</div>
                        
                        {/* <div className='d-flex justify-content-center'>
                            <div className='bold f-14 text-gray p-2 text-center r-10 mt-4 px-5'>Seguimos trabajando para que pronto puedas ver más.</div>
                        </div> */}
                            
                        </div>

                        
                        <div className='col-4 pt-2'>
                            <img width={200} src='/assets/nurse_empty.png'></img>
                        </div>
                    </div>
                </Renderif>
               

             
                {/* <div className='d-flex justify-content-center my-5'>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                           loadPage(-1)
                    }}>
                        <SlArrowLeft></SlArrowLeft>
                    </div>
                    <div className='d-flex flex-wrap px-3'>
                        {renderPagination()}
                    </div>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                           loadPage(1)
                    }}>
                        <SlArrowRight></SlArrowRight>
                    </div>
                </div> */}
             
            </div>
           

          

       
      
        </div>

        <SideDetailReception 
            active={state.show_validate} 
            profesional={state.profesiona_selected} 
            centers={state.centers}
            onClose={()=>{
                setState({...state, show_validate:false})
            }} 
            onValidate={(values)=>{
                    // onCompleteReception(values)
        }}></SideDetailReception>


        <Renderif isTrue={state.show_approving_acogida}>

            <div className='nu-modal'>

                <div className='nu-modal-body nu-modal-body--fit hide-scroll '>
                    <div className='d-flex'>
                        <div className='col'></div>
                        <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                            setState({...state, show_approving_acogida:false})
                        }}>
                            <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                        </div>
                    </div>

                    <div className='col d-flex flex-column  justify-content-center align-items-center'>
                        
                        <img width="70px" src='/assets/tick-circle.png'></img>
                        <div className='f-24 bold text-center'>Publicar turno de Acogida</div>
                        <div className='f-14  text-center mb-2'>Para que podamos asignar el turno de Cobertura solicitado primero publícale <br></br> un turno de Acogida a este profesional.</div>
                        <div className='f-14  text-center mb-2'>Cuando el profesional se lo asigne, automáticamente se asignarán los siguientes turnos:</div>
                        
                        {
                            state.solicitud_selected?.map((item, i)=>{
                                return (
                                    <div className=' bg-skyblue shadow-sm py-2 px-1 my-1  r-8 d-flex flex-wrap  flex-row align-items-center justify-content-center  w-100'>

                                        <div className='d-flex align-items-center  pe-3 col-3 '>
                                            <div className='nu-icon nu-icon--mid'>
                                            <LiaClinicMedicalSolid color='#0D78BF'></LiaClinicMedicalSolid>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{item?.center?.name}</div>
                                        </div>

                                        <div className='d-flex align-items-center pe-3 col-3 '>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="63%" src='/assets/calendar_solid.svg'></img>
                                                </div>
                                            
                                                
                                                <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(item?.shift?.date).toUTCString(), true)}</div>
                                            
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="70%" src='/assets/clock_solid.svg'></img>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{item?.shift?.initialHour} - {item?.shift?.finalHour}</div>
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <MdOutlineEditCalendar color='#0D78BF'></MdOutlineEditCalendar>
                                            </div>
                                            <div className='f-12 bold ellipsis'>Cobertura</div>
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{getProfessionalField(item?.shift?.idProfessionalField)}</div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                        
                        <div className='w-100 d-flex flex-row  align-items-center justify-content-center mt-3'>

                            <div className='nu_btn me-2 px-3 px-md-0' onClick={()=>{
                                setState({...state, show_approving_acogida:false})
                            }}>
                                <div className='nu_btn-text f-16'>Cancelar</div>
                            </div>


                            <div className='nu_btn nu_btn--primary px-3 px-md-3' onClick={()=>{
                                navigate('/clinic/publicar/', { state: {
                                    profesional:{id:parseInt(state.solicitud_selected[0].idUser), ...state.solicitud_selected[0].user}, 
                                    turno:state.solicitud_selected[0].shift,
                                    origin:'nueva_acogida'
                                }})
                                // setState({...state, show_approving_acogida:false})
                            }}>
                                <div className='nu_btn-text f-16'>Publicar Acogida</div>
                            </div>
                          


                        </div>
                      
                    </div>

                </div>
            </div>

        </Renderif>
         <Renderif isTrue={state.show_approving_sin_acogida}>

            <div className='nu-modal'>

                <div className='nu-modal-body nu-modal-body--fit hide-scroll '>
                    <div className='d-flex'>
                        <div className='col'></div>
                        <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                            setState({...state, show_approving_sin_acogida:false})
                        }}>
                            <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                        </div>
                    </div>

                    <div className='col d-flex flex-column  justify-content-center align-items-center'>
                        
                        <img width="70px" src='/assets/tick-circle.png'></img>
                        <div className='f-24 bold text-center'>Aprobar solicitud sin Acogida previa</div>
                        
                        <div className='f-14  text-center mb-2'><span className='bold'>Completar Acogida en:</span>{state.solicitud_selected[0]?.assistanceUnit?.description}</div>
                        <div className='f-14  text-center mb-2'><span className='bold'>Centro:</span> {state.solicitud_selected[0]?.center?.name}</div>
                        
                        <div className='f-14 text-center'>Entendemos que este profesional no requiere de un turno de Acogida en tu Centro. <br></br>
Se asignarán automáticamente los siguientes turnos:</div>
        
                        
                        {
                            state.solicitud_selected?.map((item, i)=>{
                                return (
                                    <div className=' bg-skyblue shadow-sm py-2 px-1 my-1  r-8 d-flex flex-wrap  flex-row align-items-center justify-content-center  w-100'>

                                        <div className='d-flex align-items-center  pe-3 col-3 '>
                                            <div className='nu-icon nu-icon--mid'>
                                            <LiaClinicMedicalSolid color='#0D78BF'></LiaClinicMedicalSolid>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{item?.center?.name}</div>
                                        </div>

                                        <div className='d-flex align-items-center pe-3 col-3 '>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="63%" src='/assets/calendar_solid.svg'></img>
                                                </div>
                                            
                                                
                                                <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(item?.shift?.date).toUTCString(), true)}</div>
                                            
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="70%" src='/assets/clock_solid.svg'></img>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{item?.shift?.initialHour} - {item?.shift?.finalHour}</div>
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <MdOutlineEditCalendar color='#0D78BF'></MdOutlineEditCalendar>
                                            </div>
                                            <div className='f-12 bold ellipsis'>Cobertura</div>
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{getProfessionalField(item?.shift?.idProfessionalField)}</div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                        
                        <div className='w-100 d-flex flex-row  align-items-center justify-content-center mt-3'>

                            <div className='nu_btn me-2 px-3 px-md-0' onClick={()=>{
                                setState({...state, show_approving_sin_acogida:false})
                            }}>
                                <div className='nu_btn-text f-16'>Cancelar</div>
                            </div>


                            <div className='nu_btn nu_btn--primary px-3 px-md-3' onClick={()=>{
                                onApproveWithOutReception()

                                
                            }}>
                                <div className='nu_btn-text f-16'>Si, asignar turnos</div>
                            </div>
                          


                        </div>
                      
                    </div>

                </div>
            </div>

        </Renderif>
        <Renderif isTrue={state.show_reason_deny}>

            <div className='nu-modal'>

                <div className='nu-modal-body'>
                    <div className='d-flex'>
                        <div className='col'></div>
                        <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                            setState({...state, show_reason_deny:false})
                        }}>
                            <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                        </div>
                    </div>
                    <div className='col d-flex flex-column justify-content-center align-items-center'>
                        <img width="70px" src='/assets/tick-circle-warning.png'></img>
                        <div className='f-22 bold text-center'>Indica los motivos de <br></br>denegación del turno</div>
                        <div className='f-14 bold text-center mt-3'>Recuerda que si lo que no te encaja es el perfil del profesional, deberías bloquearlo <br></br>para que no pueda solicitar más turnos en tu Centro.</div>

                        <textarea rows={5} value={state.reason_deny} className='nu-input f-14 mt-3' placeholder='Indica los motivos' onChange={(evt)=>{
                        setState({...state, reason_deny:evt.currentTarget.value})
                    }}></textarea>

                         <div className='w-100 d-flex flex-row  align-items-center justify-content-center mt-3'>

                            <div className='nu_btn me-2 px-3 px-md-0' onClick={()=>{
                                setState({...state, show_reason_deny:false})
                            }}>
                                <div className='nu_btn-text f-16'>Cancelar</div>
                            </div>


                            <div className='nu_btn nu_btn--primary px-3 px-md-3' onClick={()=>{
                               onDenyRequest()

                                
                            }}>
                                <div className='nu_btn-text f-16'>Denegar</div>
                            </div>
                          


                        </div>
                    </div>

                </div>
            </div>

        </Renderif>
        <Renderif isTrue={state.modalToBlock}>
          <div className='nu-modal'>
              <div className='nu-modal-body' onClick={(evt)=>{evt.stopPropagation()}}>
                  <div className='d-flex'>
                      <div className='col'></div>
                      <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                          setState({...state, modalToBlock:false})
                      }}>
                          <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                      </div>
                  </div>
                  <div className='col d-flex flex-column justify-content-center align-items-center'>
                      <img width="100px" src='/assets/tick-circle-warning.png'></img>
                      <div className='f-26 bold text-center'>Marca los motivos por los cuales este<br></br>profesional dejará de ver tus turnos publicados: </div>
                  </div>

                  <div className='py-1'></div>

                  {restrictionReasons.map((item, i)=>{
                      return (
                          <div key={i} className='px-3'>
                              <CheckBox text={item.label} value={item.type} onChange={(evt)=>{
                                if(evt.value){
                                  setState({...state, reasons: [...state.reasons, item.type]})
                                } else {
                                  state.reasons = state.reasons.filter((reason) => reason!==item.type)
                                }
                              }}/>
                          </div>
                      )
                  })}

                  <div className='pt-2 f-14 text-center text-red'>Debes elegir al menos 1 motivo para poder bloquear</div>

                  <div className='d-flex align-items-center border-bottom py-3'>
                      <div className='col d-flex justify-content-center'>
                          <div className='nu_btn nu_btn--gray' style={{ paddingInline: '2rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                              evt.stopPropagation()
                              setState({...state, modalToBlock:false})
                          }}>
                              <div className='nu_btn-text f-16'>Cancelar</div>
                          </div>
                      </div>
                      <div className='col d-flex justify-content-center'>
                          <div className='nu_btn nu_btn--overlay' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                              evt.stopPropagation()
                              onBlockProfesional()
                            //   onDenyRequest()
                          }}>
                              <div className='nu_btn-text f-16'>Aceptar</div>
                              <div className='nu_btn-overlay'></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </Renderif>

      

   
   
  
     
    </div>
    </>
  )
}


function SideDetailReception({active, profesional, centers = [], onValidate = () => {}, onClose=() => {}}) {
    const {getUserType, center, centerType,assistanceUnit, getAssistanceUnit} = useContext(GlobalContext)
    const {notify} = useContext(NotificationContext)
    const [state, setState] = useState({
        
        centers:[],
        center_selected:'',
        assistenceunit_selected:'',
        centertype_selected:'',
        loading:false,
        receptions:[],
        hoverInfo: false,
        modalToAccept: false,
        modalConfirm: false,
    })

    useEffect(() => {
        
       
     
       receptions();
    
       return () => {}

    },[profesional, active])

 
    const receptions = async (confirmed = false) => {

            setState({})

            if(active === true && centers.length !== 0) {
                setState({...state, loading:true})
                state.receptions = []
                let acogidas = []

                for (let i = 0; i < centers.length; i++) {
                    let _receptions = await Service('GET', 'completedReception/getAll?idCenter='+centers[i].id)
                    acogidas.push({
                        center:centers[i],
                        reception:_receptions.data.list.filter((reception)=>reception.idUser === profesional.id)
                    })
                    
                }
                
        
                console.log(state.receptions)

                if (!confirmed) {
                    setState({...state, receptions:acogidas,center_selected:'', centertype_selected:'', assistenceunit_selected:'', loading:false, modalToAccept:false, modalConfirm:false})
                } else {
                    setState({...state, receptions:acogidas,center_selected:'', centertype_selected:'', assistenceunit_selected:'', loading:false, modalToAccept:false, modalConfirm:true})
                }
      
              
         
                
            }
    }
    const onCompleteReception = async (values) =>{
      
        

        if(state.center_selected === ''){
            notify({title:'Validar Acogida', message:'Selecciona un centro.', type:'error'}) 
            return;
        }
        if(state.centertype_selected === ''){
            notify({title:'Validar Acogida', message:'Selecciona un tipo centro.', type:'error'}) 
            return
        }

        if(state.assistenceunit_selected === '2' && state.assistenceunit_selected === ""){
            notify({title:'Validar Acogida', message:'Selecciona una unidad asistencial.', type:'error'})
            return
        }

        setState({...state, modalToAccept:true})

        // setState({...state, loading:true})

        // let body = {}
        // body = {
        //     idCenter:parseInt(state.center_selected),
        //     idUser:parseInt(profesional.id)
        // }
        // if(values.centertype_selected === '2'){
        //     body.idAssistanceUnit = parseInt(state.assistenceunit_selected)
        // }
        // let _completeReception = await Service('POST', 'completedReception/create',body)
        
        // if(_completeReception.status === 200){
        //     notify({title:'¡Listo!', message:'Se completado turno de acogida.', type:'success'})
        //     receptions();
        //     // setState({...state, loading:false})
        //     return;
        // }
                 
        // notify({title:'Error!', message:'Ocurrio un erorr al completar turno de acogida.', type:'error'})
        // setState({...state, loading:false})
    }

    const confirmReception = async (values) => {
        setState({...state, loading:true})

        let body = {}
        body = {
            idCenter:parseInt(state.center_selected),
            idUser:parseInt(profesional.id)
        }
        if(values.centertype_selected === '2'){
            body.idAssistanceUnit = parseInt(state.assistenceunit_selected)
        }
        let _completeReception = await Service('POST', 'completedReception/create',body)
        
        if(_completeReception.status === 200){
            // notify({title:'¡Listo!', message:'Se completado turno de acogida.', type:'success'})
            receptions(true);
            setState({...state, modalToAccept:false, modalConfirm:true})
            return;
        }
        setState({...state, loading:false})
    }

    const handleMouseEnter = () => {
        setState({...state, hoverInfo:true})
    }

    const handleClose = () => {
        setState({...state, hoverInfo:false})
    }

    const hoverContentStyle = {
        position: 'absolute',
        top: '6.5rem',
        right: '1rem',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
        paddingTop: '20px',
        paddingBottom: '2px',
        paddingLeft: '25px',
        paddingRigth: '25px',
        zIndex: 1000,
        width: '22.5rem',
    };
      
    const closeButtonStyle = {
        position: 'absolute',
        top: '5px',
        right: '2px',
    };

    const paragraphStyle = {
        fontSize: '14px',
      };


  return (
    <div className={`nu-turno-detail hide-scroll ${active ? 'nu-turno-detail--active':''}`} onClick={()=>{
        // setState({...state, show_turno_detail:false })
        onClose()
    }}>
            <div className='nu-turno-content hide-scroll h-100' onClick={(evt)=>{evt.stopPropagation()}}>

                <div className='d-flex'>
                    <div className='col'></div>
                    
                    <div className='nu-icon nu-icon--mid r-100 bg-darkblue m-1 cursor-pointer' onClick={()=>{
                        // setState({...state, show_turno_detail:false})
                        onClose()
                    }}>
                        <IoMdClose color='white' size={16}></IoMdClose>
                    </div>
                </div>

                <div className=''>
                        <div className='d-flex  align-items-start b-b pb-3'>
                            {/* <div className='avatar me-2'></div> */}
                            <div className='nu-icon nu-icon--mid'>
                                <RxAvatar color='#0D78BF' size={30}></RxAvatar>
                            </div>
                            <div className=''>
                                <Renderif isTrue={profesional?.name === undefined}>
                                    <div className='f-16 bold'>Sin nombre</div>
                                </Renderif>
                                <div className='f-16 bold ps-2'>{profesional?.name}</div>

                                <div className='d-flex align-items-center ps-2'>
                       
                                        <div className='f-14 line-0'>{getUserType(profesional?.idUserType.toString())}</div>
                                        <div className='d-flex align-items-center ps-1'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <IoMdStar color='#FFB054' size={20}></IoMdStar>
                                            </div>
                                            <div className='f-14 bolder line-0'>{profesional?.stars === null ? 'Sin Valoraciones' : parseFloat(profesional?.stars).toFixed(1)}</div>
                                            <div className='nu-icon nu-icon--mid' onMouseEnter={handleMouseEnter}>
                                                <IoMdInformationCircleOutline color='gray' size={18}></IoMdInformationCircleOutline>
                                            </div>
                                            {state.hoverInfo && (
                                                <div className='hover-content' style={hoverContentStyle}>
                                                    <div style={closeButtonStyle} className='nu-icon r-100 bg-darkblue m-1 cursor-pointer' onClick={handleClose}>
                                                        <IoMdClose color='white' size={16}></IoMdClose>
                                                    </div>
                                                    {/* <button onClick={handleClose} style={closeButtonStyle}>X</button> */}
                                                    <p className='pt-2' style={paragraphStyle}>Para valorar un profesional debe hacerse desde la valoración del turno.</p>
                                                </div>
                                            )}
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                </div>

                <div className='mt-3'>
                    <div className='d-flex align-items-center'>
                        <div className='f-18 bold text-darkblue'>Acogidas Completadas</div>
                        <div className='col'></div>
                        <Renderif isTrue={state.loading}>
                            <div className="spinner-border spinner--small text-darkblue"></div>
                        </Renderif>
                    </div>

                    {
                        state.receptions?.map((reception, i) => {
                            console.log(reception)
                            return (
                                <div key={i} className="d-flex b-b pb-3 mt-2">
                                    <div className='col'>
                                        <div className='f-12 bold'>{reception.center.name}</div>
                                    </div>
                                    <div className='col'>
                                        <Renderif isTrue={reception.reception.length === 0}>
                                            <div className='f-12 bold text-gray'>Sin Acogidas</div>
                                        </Renderif>
                                        {
                                            reception.reception.map((item, j)=>{
                                                if (item.idAssistanceUnit === null) return
                                              
                                                return (
                                                    <div className='d-flex align-items-center'>
                                                         <div className='nu-icon nu-icon--mid me-1'>
                                                            <HiBadgeCheck color='#16B2DE' size={18}></HiBadgeCheck>
                                                        </div>
                                                        <div className='f-12 bold text-gray' style={{flexGrow: 1}} key={j}> {getAssistanceUnit(item.idAssistanceUnit)}</div>
                                                        <div className='nu-icon nu-icon--mid me-1'>
                                                            <FaTrash color='gray' size={14}></FaTrash>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className='mt-2'>

                    <div className='f-18 bold text-darkblue'>Validar nueva Acogida</div>
                    <div>
                            <div className='mt-2'>
                                <NuSelect value={state.center_selected} onChange={(evt)=>{
                                        setState({...state, center_selected:evt.currentTarget.value})
                                    }}>
                                    <option value="">Seleccionar centro</option>
                                    {
                                        centers.map((center, i)=>{
                                        
                                            return (
                                                <option value={center.id}>{center.id}{center.name}</option>
                                            )
                                        })
                                    }
                                
                                </NuSelect>
                            </div>

                            <div className='mt-2'>
                                <NuSelect value={state.centertype_selected} onChange={(evt)=>{
                                        setState({...state, centertype_selected:evt.currentTarget.value})
                                    }}>
                                    <option value="">Seleccionar tipo de centro</option>
                                    {alphaOrder(centerType, 'description').map((item,i)=>{
                                            if(item.id==1) return;
                                    
                                            return (
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        
                                    })}
                                </NuSelect>
                            </div>

                            <Renderif isTrue={state.centertype_selected === '2'}>
                               <div className='mt-2'>
                                    <NuSelect value={state.assistenceunit_selected} onChange={(evt)=>{
                                            setState({...state, assistenceunit_selected:evt.currentTarget.value})
                                        }}>
                                        <option value="">Seleccionar Unidad Asistencial</option>
                                        { alphaOrder(assistanceUnit, 'description').map((item,i)=>{
                                            if(item.id === 1) {return}
                                            if(item.id === 14) {return}
                                            if(item.id === 21) {return}
                                            
                                            return (
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                      
                                    })}
                                    </NuSelect>
                               </div>
                            </Renderif>



                            <div className='d-flex mt-3'>

                                <div className='col'></div>

                                <div className='nu_btn nu_btn--orange' onClick={()=>{
                                    // setState({...state, show_validate:true, profesiona_selected:item})
                                    // onCompleteReception()
                                    onCompleteReception({
                                        center_selected:state.center_selected,
                                        assistenceunit_selected:state.assistenceunit_selected,
                                        centertype_selected:state.centertype_selected,
                                    })

                                    }}>

                                    <div className='nu-icon nu-icon--mid me-1'>
                                        <HiBadgeCheck size={18}></HiBadgeCheck>
                                    </div>

                                    <div className='nu_btn-text '>Validar Acogida</div>
                                </div>
                            </div>

                    </div>
                </div>


            </div>

            <div>

            </div>
            <Renderif isTrue={state.modalToAccept}>
                <div className='nu-modal'>
                    <div className='nu-modal-body' onClick={(evt)=>{evt.stopPropagation()}}>
                        <div className='d-flex'>
                            <div className='col'></div>
                            <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                                setState({...state, modalToAccept:false})
                            }}>
                                <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                            </div>
                        </div>
                        <div className='col d-flex flex-column justify-content-center align-items-center'>
                            <img width="120px" src='/assets/tick-circle.png'></img>
                            <div className='f-30 bold text-center'>Acogida validada para<br></br>usuario {profesional?.name} </div>
                            <div className='pt-2 f-14 text-center'>Al validar una acogida para este profesional, podrá visualizar turnos<br></br>de cobertura en el centro y/o unidad que hayas seleccionado.</div>
                        </div>
                        <div className='d-flex align-items-center border-bottom py-3'>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--gray' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    setState({...state, modalToAccept:false})
                                }}>
                                    <div className='nu_btn-text f-16'>Cancelar</div>
                                </div>
                            </div>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--orange' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    confirmReception({
                                        center_selected:state.center_selected,
                                        assistenceunit_selected:state.assistenceunit_selected,
                                        centertype_selected:state.centertype_selected,
                                    })
                                }}>
                                    <div className='nu_btn-text f-16'>Sí, validar Acogida</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Renderif>
            <Renderif isTrue={state.modalConfirm}>
                <div className='nu-modal'>
                    <div className='nu-modal-body'>
                        <div className='d-flex'>
                            <div className='col'></div>
                            <div className='nu-icon nu-icon--big cursor-pointer' onClick={(evt)=>{
                                evt.stopPropagation()
                                setState({...state, modalToAccept: false, modalConfirm: false})
                            }}>
                                <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                            </div>
                        </div>
                        <div className='col d-flex flex-column justify-content-center align-items-center'>
                            <img width="120px" src='/assets/tick-circle.png'></img>
                            <div className='f-30 bold text-center'>Acogida validada para<br></br>usuario {profesional?.name} </div>
                        </div>
                    </div>
                </div>
            </Renderif>
    </div>
  )
}


function NuDropDown({item, blocked ,onSelect = () => {}}) {
    
    const [state, setState] = useState({
        open:false
    })

    const _onSelect = (key) => {
        setState({...state, open:false})
        onSelect(key)
    }

    return (
        <>
        <Renderif isTrue={state.open}>
            <div className='dropdown-overlay' onClick={(evt)=>{
                
                setState({...state, open:false})
            }}></div>
        </Renderif>

        <div className="nudropdow">
            {/* <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                setState({...state, open:!state.open})
            }}>
                <BsThreeDotsVertical size={25} color={darkBlue}></BsThreeDotsVertical>
            </div> */}
             <div ref={el => {
        // el can be null - see https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
        if (!el) return;

       
      }} className={`nu_btn nu_btn--primary me-2`} onClick={()=>{

            setState({...state, open:!state.open})
            }}>

                <div className='nu-icon nu-icon--mid me-1'>
                    <HiBadgeCheck size={18}></HiBadgeCheck>
                </div>

                <div className='nu_btn-text '>Aprobar solicitud</div>
                
            </div>
            <Renderif isTrue={state.open}>
                <div className='dropdow-items dropdow-items--bottomcenter'>
                    <div className='dropdow-item' onClick={()=>{_onSelect(0)}}>
                        {/* <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div> */}

                        <div className='nu_btn-text text-center'>Con Acogida</div>
                    </div>
                    <div className='dropdow-item' onClick={()=>{_onSelect(1)}}>
                        {/* <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div> */}

                        <div className='nu_btn-text text-center '>Sin Acogida</div>
                    </div>
                  

                  
                </div>
            </Renderif>
        </div>
        </>
    )
 
}

