import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdDocument, IoMdStar } from 'react-icons/io'
import NuLoading from '../../components/nu_loading'
import NuRating from '../../components/nu_rating'
import { AuthContext } from '../../context/auth_context'
import { GlobalContext } from '../../context/global_context'
import { NotificationContext } from '../../context/notification_context'
import { Service } from '../../services/api'
import { dMY, timeOfDay, UTCDateParser } from '../../utils'
import Renderif from '../../utils/renderif'
import TurnoConfirmationView from './turno_confirmation_view'
import { useFilePicker } from 'use-file-picker';
import { FileSizeValidator } from 'use-file-picker/validators';

export default function TurnoDetailView({active, turno, onClose= ()=>{}, onAssing = ()=>{}, onRequest=() =>{}}) {
    const {
        renderTitle, 
        getCenter, 
        getAssistanceUnit,
        getProfessionalField,
        getSpecialization,
        getCenterType,
        upLoadFile,
    } = useContext(GlobalContext)
    const {notify} = useContext(NotificationContext)

    const {user, user_data} = useContext(AuthContext)

    const { openFilePicker, filesContent, loading, plainFiles, errors } = useFilePicker({
        accept: ['.png', '.pdf', '.jpg', 'jpeg'],
        // accept: ['.png'],
        // accept: "image/*",
       
       validators:[
        // new FileTypeValidator(['jpg', 'png']),
        new FileSizeValidator({ maxFileSize: 10 * 1024 * 1024 /* 50 MB */ })
       ]
    });

    const [state, setState] = useState({
        loading:false,
        loading_receptions:false,
        showConfirmationLetter:false,
        rated:false,
        rate:1, 
        rateComment:'',
        organization:null,
        commentForNoPresent:'',
        incidentForShift:null,
        active_file_type:null,
        userReceptions:[]
    })
    const [refetch, setRefetch] = useState(false)
    
    
  
    useEffect(() => {

        const init = async () => {

           

         if(turno){
            setState({...state, loading_receptions:true})
            let _organization = await Service('GET', `organizationData/getById/${turno?.idOrganization}`)
            let _receptions = await Service('GET', `completedReception/getAll?idUser=${user.id}`)
         
            state.organization = _organization.data.organizationData;
            state.userReceptions = _receptions.data.list;
            
            // let _organization = await Service('GET', `organizationData/getById/${turno.idOrganization}`)
            let _valoraciones = await Service('GET', 'rating/getAll');
            console.log(_valoraciones);
            let  _rated = await Service('GET', `rating/checkIfExist?type=1&idCenter=${turno?.idCenter}&idUser=${user.id}&idShift=${turno?.id}`)
            console.log(_rated.data);
            let _incidents = await Service('GET', 'incident/getAll')
            const incident = _incidents?.data?.list?.find(item => item.idShift === turno?.id)
            
            setState({...state, loading_receptions:false, rated:_rated.data.exist, organization:_organization.data.organizationData, incidentForShift:incident})
         }else{
            setState({...state, loading_receptions:false})
         }
         setRefetch(false)
        }
        init();
        return () => {}
    },[turno, refetch])

    useEffect( ()=>{
        const init = async () => {
            if(errors.length > 0){
                notify({title:'¡Error!', message:'Debes subir un archivo no mayor a 10MB', type:'error'})
            }else{
                if(filesContent.length > 0 && state.active_file_type !== null){     
                    setState({...state, loading:true})
                    let _upload = await upLoadFile(plainFiles[0], state.active_file_type)
                    if (_upload?.data?.document) {
                        const idDocument = _upload?.data?.document?.id
                        let body = {
                            ...state.incidentForShift,
                            idDocument: idDocument
                        }
                        let _incident = await Service('PUT', 'incident/update', body);
                        if (_incident.status === 201) {
                            notify({title:'¡Listo!', message:'Tu documento de justificación fue enviado', type:'success'})
                            setRefetch(true)

                        } else {
                            notify({title:'Error!', message:'Ocurrio un error al mandar tu documento.', type:'error'})
                        }
                    } else {
                        notify({title:'Error!', message:'Ocurrio un error al mandar tu documento.', type:'error'})
                    }
                    state.active_file_type = null
                    setState({...state, loading:false})
                }
            }
        }
        init();
        return () => {}
    },[filesContent, errors])


    const onAssingTurno = async () => {
        

        setState({...state, loading:true})
      
        let _assign = await Service('PUT', `shift/take/${turno.id}/1`);
        if(_assign.status === 201){
            onAssing(true)
        }else{
            onAssing(false)
        }
        setState({...state, loading:false, showConfirmationLetter:false})
        

    }
    const checkCompleted = () => {
    
        let _today = new Date();
        var end_turno = parseInt(turno?.initialHour.split(':')[0])
        var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0)).getTime()
        let _date = new Date(turno?.date).getTime()

        if(today > _date){
            return true
        }
        if(today < _date){
            return false;
        }
        if(today === _date){
            if(_today.getHours() > end_turno){
                return true
            }
            return false;
        }
    }
    const onRateTurno = async() => {
  
        setState({...state, loading:true})

        let body = {
            type: '1',
            //Propiedad type es un enum, solo acepta los valores "0" y "1"
            //"0" - Valoración del centro al nurse/tcae/student
            //"1" - Valoración del nurse/tcae/student al centro
            idCenter: turno.idCenter,
            idUser: user.id,
            idShift: turno.id,
            stars: state.rate, //Min 0, Max 5
            comment: state.rateComment
        }

        let _rate = await Service('POST', 'rating/create', body)
        console.log(_rate)
       
        if(_rate.status === 201){
            notify({title:'¡Listo!', message:'Valoración enviada.', type:'success'})
            onClose();
        }else{
            notify({title:'Error!', message:_rate?.response?.data?.error, type:'error'})
            onClose();
        }
    
        setState({...state, loading:false, rated:true})
      

        

    }

    const onUnattendedShift = async (idShift) => {
        setState({...state, loading:true})

        if (!state.incidentForShift) {
            notify({title:'Error!', message:'Ocurrio un error al mandar tu respuesta.', type:'error'})
            setState({...state, loading:false})
            return
        }

        let body = {
            ...state.incidentForShift,
            user_comment: state.commentForNoPresent,
        }
        // console.log("body: ", body);
        
        let _incident = await Service('PUT', 'incident/update', body);
        // console.log(_incident)
        if (_incident.status === 201) {
            notify({title:'¡Listo!', message:'Tu justificación fue enviada', type:'success'})
            setRefetch(true)
        } else {
            notify({title:'Error!', message:'Ocurrio un error al mandar tu justificación.', type:'error'})
        }
        setState({...state, loading:false, commentForNoPresent: ""})
    }

    const checkReception = (turno) => {
        let value = false

            switch (turno?.needsReception) {
                case '1':
                    let _center = state.userReceptions?.filter((item)=>{
                        if(item.idCenter === turno.idCenter){
                            return true
                        }else{
                            return false
                        }
                    })

                    if(_center.length === 0){
                        value = true
                    }
                    
                    break;
                case '2':
                    
                    let _assistenceUnit = state.userReceptions?.filter((item)=>{
                        if(item.idCenter === turno.idCenter && item.idAssistanceUnit === turno.idAssistanceUnit){
                            return true
                        }else{
                            return false
                        }
                    })

                    if(_assistenceUnit.length === 0){
                        value = true
                    }
            
                default:
                    break;
            }
    
            
        return value;
    }

  return (
    <>
     <Renderif isTrue={state.loading}>
        <NuLoading />
    </Renderif>
    <Renderif isTrue={state.showConfirmationLetter}>
        <TurnoConfirmationView 
            turno={turno}
            onAccept={(evt)=>{
                onAssingTurno();
            }} 
            onClose={(evt)=>{
                setState({...state, showConfirmationLetter:false})
            }} 
        />
    </Renderif>
    <div className={`nu-turno-detail hide-scroll ${active ? 'nu-turno-detail--active':''}`} onClick={()=>{
        // setState({...state, show_turno_detail:false })
        onClose()
    }}>
    
        <div className='nu-turno-content hide-scroll h-100' onClick={(evt)=>{evt.stopPropagation()}}>
            <div className='d-flex'>
                <div>
                    <div className='f-22 bold'>
                        {getCenter(turno?.idCenter)?.name}
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='nu-icon nu-icon--mid'>
                            <IoMdStar color='#FFB054' size={24}></IoMdStar>
                        </div>
                        <div className='f-14 bold'>5.0 (0 Valoraciones)</div>
                    </div>
                </div>
                <div className='col'></div>
                
                <div>
                <div className='nu-icon nu-icon--mid r-100 bg-darkblue m-1 cursor-pointer' onClick={()=>{
                    // setState({...state, show_turno_detail:false})
                    onClose()
                }}>
                    <IoMdClose color='white' size={16}></IoMdClose>
                </div>
                </div>
            </div>

            <div className='mt-3'>
                <div className='nu-turno-detail-image r-8' style={{backgroundImage:'url(../assets/img_clinic.png)'}}></div>
                {/* <img  width="100%" height={150} className='r-10' src='https://picsum.photos/300/120'></img> */}
            </div>
            
            <div className='mt-2'>

                <Renderif isTrue={state.loading_receptions}>
                    <div className='d-flex justify-content-center'>
                        <div className="spinner-border spinner--mid text-darkblue"></div>
                    </div>
                </Renderif>
            
                <Renderif isTrue={!state.loading_receptions}>
                    <Renderif isTrue={turno?.needsReception === '0' || turno?.needsReception === null || !checkReception(turno)}>
                        <div className={`nu_btn nu_btn--${turno?.status === '1' || turno?.status === '3' || checkCompleted() ? 'disabled' : 'darkgreen'} w-100`} onClick={() => {

                            if(user_data.status !== "1"){
                                notify({title:'Lo sentimos!', message:'Tu perfil no ha sido validado.', type:"error"})
                                return;
                            }
                        
                            if(turno?.status === '0' && !checkCompleted()){
                                setState({...state, showConfirmationLetter:true})
                            }

                        }}>
                            <div className='nu_btn-text f-14'>Asignarme turno</div>
                        </div>
                    </Renderif>

                    
                    {/* <Renderif isTrue={checkReception(turno)}>
                        <div className={`nu_btn nu_btn--${turno?.status === '1' || turno?.status === '3' || checkCompleted() ? 'disabled' : 'orange'} w-100`} onClick={()=>{
                        
                            onRequest()
                        }}>
                            <div className='nu_btn-text f-14'>Solicitar turno</div>
                        </div>
                    </Renderif> */}
                </Renderif>

            </div>

            <div className='mt-3'>
                    <div className='f-22 bold'>
                   
                </div>
            </div>

                <div className='d-flex mt-3'>

                    <div className='me-3'>
                        <div className='d-flex mt-0'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="80%" src='/assets/calendar_solid.svg'></img>
                            </div>
                            <div className='ps-2'>
                                <div className='f-14 bold'>Fecha</div>
                                <div className='f-12'>{UTCDateParser(new Date(turno?.date).toUTCString(), true)}</div>
                            </div>
                        </div>

                        <div className='d-flex mt-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="80%" src='/assets/clock_solid.svg'></img>
                            </div>
                            <div className='ps-2'>
                                <div className='f-14 bold'>Hora de inicio</div>
                                <div className='f-12 '>{turno?.initialHour} hrs.</div>
                                
                            </div>
                        </div>
                    </div>

                    <div className='ms-3'>

                        <div className='d-flex mt-0'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="80%" src='/assets/calendar_clock_solid.svg'></img>
                            </div>
                            <div className='ps-2'>
                                <div className='f-14  bold'>Turno de</div>
                                <div className='f-12 '>{timeOfDay(turno?.timeOfDay)}</div>
                            </div>
                        </div>

                        <div className='d-flex mt-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="65%" src='/assets/euro_symbol_solid.svg'></img>
                            </div>
                            <div className='ps-2'>
                                <div className='f-14 bold'>{turno?.remuneration}</div>
                                <div className='f-12 opacity-0'>empty</div>
                            
                            </div>
                        </div>

                    </div>
                </div>

            <div className='mt-3'>

        
                <div className='mt-3'>
                    <div className='f-18 bold mb-2'> Detalle del  turno</div>
                    <div className='f-14'><span className='bold'>Organización</span>: {state.organization?.name}</div>
                    <div className='f-14'><span className='bold'>Nombre del centro</span>: {getCenter(turno?.idCenter)?.name}</div>
                    <div className='f-14'><span className='bold'>Tipo de centro</span>: {getCenterType(turno?.idCenterType)}</div>
                    <div className='f-14'><span className='bold'>Inicio del turno</span>: {turno?.initialHour} hrs.</div>
                    <div className='f-14'><span className='bold'>Fin del turno</span>: {turno?.finalHour} hrs.</div>
                    <Renderif isTrue={turno?.idAssistanceUnit !== null}>
                        <div className='f-14'><span className='bold'>Unidad Asistencial</span>: {getAssistanceUnit(turno?.idAssistanceUnit)}</div>
                    </Renderif>
                 
                    <div className='f-14'><span className='bold'>Ámbito Profesional</span>: {getProfessionalField(turno?.idProfessionalField)}</div>
                </div>

                {turno?.description ? (
                    <div className='mt-3'>
                        <div className='f-18 bold mb-2'>Descripción del turno</div>
                        <div className='f-14'>{turno?.description}</div>
                    </div>
                ) : (
                    <div className='mt-3'>
                        <div className='f-18 bold mb-2'>Descripción del turno</div>
                        <div className='f-14'>Este turno no tiene descripción.</div>
                    </div>
                )}

                <div className='mt-3'>
                    <div className='f-18 bold mb-2'>Información del centro</div>
                    {/* <div className='f-14'><span className='bold'>Parking</span>: Texto Libre</div>
                    <div className='f-14'><span className='bold'>Dietas</span>: Texto Libre</div>
                    <div className='f-14'><span className='bold'>Uniforme</span>: Texto Libre</div>
                    <div className='f-14'><span className='bold'>Punto de encuentro</span>: Texto Libre</div> */}
                     {/* <div className='f-14'><span className='bold'>Notas</span>: {getCenter(turno?.idCenter)?.description}</div> */}
                    <div className='f-14'><span className='bold'>Dirección</span>: {getCenter(turno?.idCenter)?.address}</div>
                    <div className='f-14'><span className='bold'>Código postal</span>: {getCenter(turno?.idCenter)?.postalCode}</div>
              
                    <div className='turno-info-center' dangerouslySetInnerHTML={{__html: getCenter(turno?.idCenter)?.description}}></div>
                </div>
                <Renderif isTrue={checkCompleted() && turno?.idUser === user.id && state.rated === true}>
                    <div className='mt-3'>
                        <div className='f-12 bold text-darkblue'>Valoración enviada.</div>
                    </div>
                </Renderif>
                {/* <Renderif isTrue={2==2}> */}
                <Renderif isTrue={checkCompleted() && turno?.idUser === user.id && !state.rated && turno?.status !== "4"}>
                    
                    <div className='mt-3'>
                        <div className='f-18 bold'>Valorar turno</div>
                        <div className='mt-2'>
                                <NuRating value={0} onChange={(value)=> {
                                        setState({...state, rate:value})

                                }}/>

                                <textarea className='nu-input mt-3' onChange={(evt)=>{
                                    setState({...state, rateComment:evt.currentTarget.value})
                                }}></textarea>

                                <div className={`nu_btn nu_btn--primary mt-1`} onClick={(evt) => {
                                     onRateTurno()
                                }}>
                                    <div className='nu_btn-text'>Enviar</div>
                                </div>
                        </div>
                    </div>
                </Renderif>

                <Renderif isTrue={turno?.status === "4"}>
                    <div className='mt-3'>
                        <div className='f-18 bold'>Justificación</div>
                        <Renderif isTrue={!state.incidentForShift?.user_comment}>
                            <div className='f-12'>Coméntanos la justificación de tu ausencia. Puedes subir cualquier documentación.</div>
                            <div className='mt-2'>
                                <textarea rows={5} placeholder='Añade un comentario...' className='nu-input mt-3 f-13' value={state.commentForNoPresent} onChange={(evt)=>{
                                    setState({...state, commentForNoPresent:evt.currentTarget.value})
                                }}></textarea>
                                <div className='d-flex align-items-center border-bottom py-3'>
                                    <div className={`nu_btn nu_btn--gray mx-2 mt-1`} onClick={(evt) => {
                                        state.active_file_type = '11'
                                        openFilePicker()
                                    }}>
                                        <div className='nu_btn-text'>Subir documentación</div>
                                    </div>
                                    <div className={`nu_btn nu_btn--primary mx-2 mt-1`} onClick={(evt) => {
                                        onUnattendedShift(turno?.id)
                                    }}>
                                        <div className='nu_btn-text'>Enviar</div>
                                    </div>
                                </div>
                            </div>
                        </Renderif>
         
                        <Renderif isTrue={state.incidentForShift?.user_comment}>
                            <div className='d-flex align-items-center border-bottom py-2'>
                                <div className='f-14'>
                                    {`Este es tu comentario de justificación: ${state.incidentForShift?.user_comment}`}
                                </div>
                            </div>
                            <div className='d-flex align-items-center border-bottom py-2'>
                                <div className="nu-icon" style={{ height: '24px', width: '24px' }}>
                                    <IoMdDocument size={28} color='#0dcaf0'></IoMdDocument>
                                </div>
                                <div className='f-14' style={{ paddingLeft: '0.5em' }}>
                                    {state.incidentForShift?.document
                                        ? `- Usted proporcionó el siguiente documento: ${
                                            state.incidentForShift?.document?.key?.substring(
                                                state.incidentForShift?.document?.key?.lastIndexOf('/') + 1,
                                                state.incidentForShift?.document?.key.length
                                            )
                                        }`
                                        : '- No se ha proporcionado ningún documento.'
                                    }
                                </div>
                            </div>
                            <div className={`nu_btn nu_btn--gray mx-2 mt-1`} onClick={(evt) => {
                                state.active_file_type = '11'
                                openFilePicker()
                            }}>
                                <div className='nu_btn-text'>Subir documentación</div>
                            </div>
                        </Renderif>
                    </div>
                </Renderif>

            </div>
        </div>
    </div>
    </>
  )
}
