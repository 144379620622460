import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaTrash, FaHeart, FaRegHeart  } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { MdOutlinePersonOff } from "react-icons/md";
import { Service } from '../../services/api';
import Renderif from '../../utils/renderif';
import { GlobalContext } from '../../context/global_context';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '../../context/notification_context';
import { AuthContext } from '../../context/auth_context';
import NuLoading from '../../components/nu_loading';
import { LuBadgeCheck } from "react-icons/lu";
import { HiBadgeCheck } from "react-icons/hi";
import { RxAvatar } from "react-icons/rx";
import NuSelect from '../../components/nu_select';
import { alphaOrder } from '../../utils';
import { BiFilter, BiSearch, BiTrash } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { darkBlue } from '../../styles/colors';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoCloseCircle } from 'react-icons/io5';

export default function ProfesionalesClinicaView() {

    const {user_data, user} = useContext(AuthContext)
    const {getUserType, center, centerType,assistanceUnit} = useContext(GlobalContext)
    const {notify} = useContext(NotificationContext)
    const navigate = useNavigate()
    const [state, setState] = useState({
        filters:'',
        page:1,
        count:7,
        professionals:[],
        professionalsCount:0, 
        bloqueos:[],
        loading:false,
        show_validate:false,
        profesiona_selected:null,
        centers:[],
        // center_selected:'',
        // assistenceunit_selected:'',
        // centertype_selected:'',
        search:'',

    })
    const [shiftCounts, setShiftCounts] = useState({});


    useEffect(()=>{

        init();
        return () => {}
    },[])

    const init = async () => {
      
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:state.page, count:state.count, status:state.filters})
        console.log(_profesionals)
        state.professionals = _profesionals.data.list
        state.professionalsCount = _profesionals.data.total
        state.professionals = await Promise.all(state?.professionals?.map(async (item) => {
            const userData = await Service ('GET', `document/view/${item?.id}/10`)
            let objectWithImage = null
            if (userData?.data?.error) {
                objectWithImage = {
                    ...item,
                    image: null
                }
            }
            if (userData?.data?.url) {
                objectWithImage = {
                    ...item,
                    image: userData.data.url
                }
            }
            return objectWithImage
        }))
        setState({...state, loading:false})
    }

    const loadProfessionals = async ({page, count, search = ''}) => {
        let query = `user/getMyProfessionals?page=${page}&pageCount=${count}`
        if(search !== ''){
            query = query+'&term='+search
        }
        let _profesionals = await Service('GET', query)

        console.log(_profesionals)
        let _bloqueos = await Service('GET', 'restriction/getAll');

        let my_centers = center.filter((item)=>item.idOrganization === user_data?.id);

        state.centers = my_centers

        let bloqueos = []

        for (let i = 0; i < my_centers.length; i++) {
            console.log(my_centers[i])
            _bloqueos.data.list.map((bloqueo)=>{
                if(bloqueo.idCenter === my_centers[i].id){
                    bloqueos.push(bloqueo)
                }
            })
        }
        

        console.log(bloqueos)
        state.bloqueos = bloqueos;


        // console.log(_bloqueos);
        // console.log(_profesionals);
        return _profesionals;

    }

    const onBlockProfesional = async  (idUser) => {

    }

    const onUnblockProfesional = async (idUser) => {
        let _bloqueo = state.bloqueos.filter((bloqueo) => bloqueo.idUser === idUser)[0]
        let _unblock = await Service('DELETE', 'restriction/delete/'+_bloqueo.id);
        if(_unblock.status === 200){
            notify({title:'¡Listo!', message:'Profesional desbloqueado', type:'success'})
            init();
        }else{
            notify({title:'Erorr!', message:'No se ha podiado desbloquear este profesional.', type:'error'})
        }
    }

    const onFavoriteProfessional = async (idUser) => {
        setState({...state, loading:true})
        let _favorite = await Service ('POST', `favorite/create/${idUser}`)
        
        if(_favorite.status === 200){
            notify({title:'¡Listo!', message:'Profesional marcado como favorito', type:'success'})
        
            init();

            
        }else{
            notify({title:'Erorr!', message:'No se ha podiado marcar como favorito a este profesional.', type:'error'})
            setState({...state, loading:false})
        }
    }

    
    const onUnfavoriteProfessional = async (idUser) => {
        setState({...state, loading:true})
        let _unfavorite = await Service ('DELETE', `favorite/delete/${idUser}`)
        if(_unfavorite.status === 200){
            notify({title:'¡Listo!', message:'Profesional marcado como no favorito', type:'success'})

          
            init();


        }else{
            notify({title:'Erorr!', message:'No se ha podiado marcar como no favorito a este profesional.', type:'error'})
            setState({...state, loading:false})
        }
    }

   

    const renderPagination = () => {

        let pages = Math.ceil(state.professionalsCount / state.count);
        let items = []
        for (let i = 1; i <= pages; i++) {
            items.push(i);
        }

        return (
            <div>
               <span className='bold'>{state.page}</span> de <span className='bold'>{items.length}</span>
            </div>
        )


        // let pages = Math.ceil(state.professionalsCount / state.count);
        // let items = []
        // for (let i = 1; i <= pages; i++) {
        //     items.push(i);
        // }

        // // items.reverse()

        // return items.map((item, i) => {
           
        //     return (
        //         <div key={i} style={{width:32}} className={`f-16 bold text-dark px-2 cursor-pointer mb-1 r-10 text-center ${state.page === item ? 'bg-light':'' }`} onClick={async ()=>{
                    
        //             setState({...state, loading:true})
        //             let _profesionals = await loadProfessionals({page:item, count:state.count, status:state.filters})

          
        
                    
        //             setState({...state, 
        //                 page:item,
        //                 loading:false, 
        //                 professionals:_profesionals.data.list, 
        //                 professionalsCount:_profesionals.data.total,
                
        //             })
        //         }}>{item}</div>
        //     )
        // })
        
        


     

    }

    const userBlocked = (user) => {

        let blocked = false;
        state.bloqueos.map((bloqueo)=>{
        
            if(bloqueo.idUser === user.id){
           
                blocked = true;
       
            }
        })
        console.log(blocked)
        return blocked;
    }
    const loadPage = async (page) => {
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:state.page + page, count:state.count,  search:state.search})
        state.page = state.page + page
        state.professionals = _profesionals.data.list
        state.professionals = await Promise.all(state?.professionals?.map(async (item) => {
            const userData = await Service ('GET', `document/view/${item?.id}/10`)
            let objectWithImage = null
            if (userData?.data?.error) {
                objectWithImage = {
                    ...item,
                    image: null
                }
            }
            if (userData?.data?.url) {
                objectWithImage = {
                    ...item,
                    image: userData.data.url
                }
            }
            return objectWithImage
        }))
        setState({...state, 
            // page:state.page + page,
            loading:false, 
            // professionals:_profesionals.data.list, 
            professionalsCount:_profesionals.data.total,
    
        })
        //  save_state()
    }

    const onSearch = async () => {
       
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:1, count:state.count, search:state.search })
        console.log(_profesionals)
        state.professionals = _profesionals.data.list
        state.professionalsCount = _profesionals.data.total
        state.professionals = await Promise.all(state?.professionals?.map(async (item) => {
            const userData = await Service ('GET', `document/view/${item?.id}/10`)
            let objectWithImage = null
            if (userData?.data?.error) {
                objectWithImage = {
                    ...item,
                    image: null
                }
            }
            if (userData?.data?.url) {
                objectWithImage = {
                    ...item,
                    image: userData.data.url
                }
            }
            return objectWithImage
        }))
        state.page = 1
        setState({...state, loading:false})
        // save_state()
        
}

    useEffect(() => {
        const fetchShiftCountsForItem = async (item) => {
            try {
                let _count = await Service('GET', `shift/getAll/?idUser=${item.id}&idOrganization=${user_data.id}`)
                console.log('---> ', _count.data.list.length)
                setShiftCounts(prevCounts => ({
                    ...prevCounts,
                    [item.id]: _count?.data?.list?.length || 0
                }))
            } catch (error) {
                console.error("Error fetching shift count for item: ", error)
                setShiftCounts(prevCounts => ({
                    ...prevCounts,
                    [item.id]: 0
                }))
            }
        }

        state.professionals?.forEach(item => {
            fetchShiftCountsForItem(item)
        })
    }, [state.professionals])
   

    


  



  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <ClinicSideMenu page='profesionales' />
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

            <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            {/* <div className='f-16 text-white'>5.0 (0 reseñas)</div> */}
                            <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container-fluid container-xl col mx-auto p-3'>


                <div className='f-24  bold mt-2'>Profesionales</div>
                <div className='d-flex align-items-end mt-3'>
                    <div className='col-5'>
                     
                       <input className="nu-input--light p-2" value={state.search} placeholder='Busqueda por nombre. Ej. Roberto' onChange={(evt)=>{
                        setState({...state, search:evt.currentTarget.value})
                       }}></input>
                    </div>

                    

                    <div className='col d-flex'>
                       
                            <div className='nu_btn nu_btn--primary ms-2 py-2' onClick={()=>{
                                    // onDeleteUser(item)
                                    onSearch()
                                    
                                }}>

                                <div className='nu-icon nu-icon--mid'>
                                    <BiSearch size={20}></BiSearch>
                                </div>

                                <div className='nu_btn-text '>Buscar</div>
                            </div>

                            <Renderif isTrue={state.search !== '' }>
                                <div className='nu_btn ms-2 py-2' onClick={()=>{
                                        state.search = ''
                                        state.filters = ''
                                        state.filterUserType = ''
                                        // state.page = 1
                                        onSearch()
                                        
                                    }}>

                                    <div className='nu-icon nu-icon--mid'>
                                        <BiFilter size={20}></BiFilter>
                                    </div>

                                    <div className='nu_btn-text '>Limpiar Filtro</div>
                                </div>
                            </Renderif>
                            
                      
                    </div>
                </div>

                <div>
                    { 
                        // professionals?.map((item, i)=>{
                        state.professionals?.map((item, i)=>{
                           
                            // if(userBlocked(item) === false) return;
                            
                      
                            return(
                                <div key={i} className='d-flex align-items-center border-bottom py-3'>
                            
                                    <div className='col'>
                                        
                                        <div className='d-flex  align-items-start'>
                                            {/* <div className='nu-icon nu-icon--mid'>
                                                <RxAvatar color='#0D78BF' size={30}></RxAvatar>
                                            </div> */}
                                            <div className='avatar me-2' style={{backgroundImage: item?.image ? `url(${item.image})` : 'url(/assets/avatar.png)'}}></div>
                                          

                                            <div className='ps-2'>
                                                <Renderif isTrue={item?.name === undefined}>
                                                    <div className='f-14 bold'>Sin nombre</div>
                                                </Renderif>
                                                <div className='f-14 bold'>{item?.name}</div>
                                                <div className='f-12'>{getUserType(item?.idUserType.toString())}</div>
                                            </div>
                                        </div>

                        
                                    </div>
                                    {/* <div className='col'>
                                        
                                        <div className='f-14 bold'>{
                                            item?.status === "0" ? 'Pendiente de validar' : 'Perfil Validado'
                                        }</div>
                                        <Renderif isTrue={item.documentationCompleted === true && item.status === '0'}>
                                            <div className='f-12 bold text-success'>En revisión</div>
                                        </Renderif>

                                    </div> */}
                                    <div className='col'>
                                        <div className='f-14 bold'>{shiftCounts[item.id] !== undefined ? `${shiftCounts[item.id]} turno(s)` : '0 turno(s)'}</div>
                                    </div>

                                    {/* <div className='col'>
                                        <div className='f-14 bold'>{item?.email}</div>
                                    </div> */}
                                    <div className='col'>

                                        <div className='d-flex align-items-center'>
                                            <div className='nu-icon nu-icon--mid'>
                                                {item?.favorite ? (
                                                    <FaHeart color="red" size={15} style={{cursor: 'pointer'}} onClick={() => onUnfavoriteProfessional(item?.id)}></FaHeart>
                                                ) : (
                                                    <FaRegHeart color="red" size={15} style={{cursor: 'pointer'}} onClick={() => onFavoriteProfessional(item?.id)}></FaRegHeart>
                                                )}
                                            </div>
                                            <div className='nu-icon nu-icon--mid'>
                                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                            </div>
                                            <div className='f-12 bolder'>{item.stars === null ? 'Sin Valoraciones' : parseFloat(item.stars).toFixed(1)}</div>
                                       
                                            
                                        </div>
                                        
                                    </div>
                                    {/* <div className='pe-1'>
                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                                setState({...state, show_turno_detail:true})
                                            }}>

                                            <div className='nu-icon nu-icon--mid'>
                                                <IoIosSearch size={20}></IoIosSearch>
                                            </div>

                                            <div className='nu_btn-text '>Ver perfil</div>
                                        </div>
                                    </div> */}
                                    <div className='px-1'>

                                        <div className={`nu_btn ${userBlocked(item) ? 'nu_btn--block' : 'nu_btn--primary'}`} onClick={()=>{
                                                navigate('/clinic/profesionales/'+item.id)
                                            }}>


                                            <div className='nu_btn-text '>Ver perfil</div>
                                        </div>
                                    </div>
                                     <div className='px-1'>

                                        <div className={`nu_btn ${userBlocked(item) ? 'nu_btn--disabled' : 'nu_btn--primary'}`} onClick={()=>{
                                               setState({...state, show_validate:true, profesiona_selected:item})
                                            }}>

                                            <div className='nu-icon nu-icon--mid me-1'>
                                                <HiBadgeCheck size={18}></HiBadgeCheck>
                                            </div>

                                            <div className='nu_btn-text '>Validar Acogida</div>
                                        </div>
                                    </div>

                                    <div className='px-1'>
                                        <div className={`nu_btn ${userBlocked(item) ? 'nu_btn--disabled' : 'nu_btn--overlay'}`} onClick={()=>{

                                                navigate('/clinic/publicar/', { state: {profesional:item} } )
                                        }}>
                                            <div className='nu_btn-text'>Publicar turno</div>
                                            <div className='nu_btn-overlay'></div>
                                        </div>
                                    </div>

                                    {/* <div>
                                        
                                        <NuDropDown item={item} blocked={userBlocked(item)} onSelect={(key)=>{
                                            switch (key) {
                                                    case 0:
                                                        navigate('/clinic/profesionales/'+item.id)
                                                        break;
                                                    case 1:
                                                        navigate('/clinic/documentacion/'+item.id)
                                                        break;
                                                    case 2:
                                                        break;
                                                    case 3:
                                                        onUnblockProfesional(item.id)
                                                        break;
                                                default:
                                                    break;
                                            }
                                        }}></NuDropDown>
                                    </div> */}

                                    {/* <div className='ps-1'>
                                         
                                        <Renderif isTrue={userBlocked(item) === false}>
                                            <div className='nu_btn nu_btn--danger' onClick={()=>{
                                                    onBlockProfesional(item.id)
                                                }}>

                                                <div className='nu-icon nu-icon--mid'>
                                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                                </div>

                                                <div className='nu_btn-text ps-2'>Bloquear</div>
                                            </div>
                                        </Renderif>

                                        <Renderif isTrue={userBlocked(item) === true}>
                                            <div className='nu_btn nu_btn--light' onClick={()=>{
                                              onUnblockProfesional(item)
                                                }}>

                                                <div className='nu-icon nu-icon--mid'>
                                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                                </div>

                                                <div className='nu_btn-text ps-2'>Desbloquear</div>
                                            </div>
                                        </Renderif>

                                      

                                    </div> */}


                                </div>
                            )})

                    }

                </div>

                <div className='d-flex justify-content-center my-5'>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                           loadPage(-1)
                    }}>
                        <SlArrowLeft></SlArrowLeft>
                    </div>
                    <div className='d-flex flex-wrap px-3'>
                        {renderPagination()}
                    </div>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                           loadPage(1)
                    }}>
                        <SlArrowRight></SlArrowRight>
                    </div>
                </div>
             
            </div>
           

          

       
      
        </div>

        <SideDetailReception 
        active={state.show_validate} 
        profesional={state.profesiona_selected} 
        centers={state.centers}
        onClose={()=>{
            setState({...state, show_validate:false})
        }} 
        onValidate={(values)=>{
                    // onCompleteReception(values)
            }} ></SideDetailReception>

      

   
   
  
     
    </div>
    </>
  )
}


function SideDetailReception({active, profesional, centers = [], onValidate = () => {}, onClose=() => {}}) {
    const {getUserType, center, centerType,assistanceUnit, getAssistanceUnit} = useContext(GlobalContext)
    const {notify} = useContext(NotificationContext)
    const [state, setState] = useState({
        
        centers:[],
        center_selected:'',
        assistenceunit_selected:'',
        centertype_selected:'',
        loading:false,
        receptions:[],
        hoverInfo: false,
        modalToAccept: false,
        modalConfirm: false,
    })

    useEffect(() => {
        
       
     
       receptions();
    
       return () => {}

    },[profesional, active])

 
    const receptions = async (confirmed = false) => {

            setState({})

            if(active === true && centers.length !== 0) {
                setState({...state, loading:true})
                state.receptions = []
                let acogidas = []

                for (let i = 0; i < centers.length; i++) {
                    let _receptions = await Service('GET', 'completedReception/getAll?idCenter='+centers[i].id)
                    acogidas.push({
                        center:centers[i],
                        reception:_receptions.data.list.filter((reception)=>reception.idUser === profesional.id)
                    })
                    
                }
                
        
                console.log(state.receptions)

                if (!confirmed) {
                    setState({...state, receptions:acogidas,center_selected:'', centertype_selected:'', assistenceunit_selected:'', loading:false, modalToAccept:false, modalConfirm:false})
                } else {
                    setState({...state, receptions:acogidas,center_selected:'', centertype_selected:'', assistenceunit_selected:'', loading:false, modalToAccept:false, modalConfirm:true})
                }
      
              
         
                
            }
    }
    const onCompleteReception = async (values) =>{
      
        

        if(state.center_selected === ''){
            notify({title:'Validar Acogida', message:'Selecciona un centro.', type:'error'}) 
            return;
        }
        if(state.centertype_selected === ''){
            notify({title:'Validar Acogida', message:'Selecciona un tipo centro.', type:'error'}) 
            return
        }

        if(state.assistenceunit_selected === '2' && state.assistenceunit_selected === ""){
            notify({title:'Validar Acogida', message:'Selecciona una unidad asistencial.', type:'error'})
            return
        }

        setState({...state, modalToAccept:true})

        // setState({...state, loading:true})

        // let body = {}
        // body = {
        //     idCenter:parseInt(state.center_selected),
        //     idUser:parseInt(profesional.id)
        // }
        // if(values.centertype_selected === '2'){
        //     body.idAssistanceUnit = parseInt(state.assistenceunit_selected)
        // }
        // let _completeReception = await Service('POST', 'completedReception/create',body)
        
        // if(_completeReception.status === 200){
        //     notify({title:'¡Listo!', message:'Se completado turno de acogida.', type:'success'})
        //     receptions();
        //     // setState({...state, loading:false})
        //     return;
        // }
                 
        // notify({title:'Error!', message:'Ocurrio un erorr al completar turno de acogida.', type:'error'})
        // setState({...state, loading:false})
    }

    const confirmReception = async (values) => {
        setState({...state, loading:true})

        let body = {}
        body = {
            idCenter:parseInt(state.center_selected),
            idUser:parseInt(profesional.id)
        }
        if(values.centertype_selected === '2'){
            body.idAssistanceUnit = parseInt(state.assistenceunit_selected)
        }
        let _completeReception = await Service('POST', 'completedReception/create',body)
        
        if(_completeReception.status === 200){
            // notify({title:'¡Listo!', message:'Se completado turno de acogida.', type:'success'})
            receptions(true);
            setState({...state, modalToAccept:false, modalConfirm:true})
            return;
        }
        setState({...state, loading:false})
    }

    const handleMouseEnter = () => {
        setState({...state, hoverInfo:true})
    }

    const handleClose = () => {
        setState({...state, hoverInfo:false})
    }

    const hoverContentStyle = {
        position: 'absolute',
        top: '6.5rem',
        right: '1rem',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
        paddingTop: '20px',
        paddingBottom: '2px',
        paddingLeft: '25px',
        paddingRigth: '25px',
        zIndex: 1000,
        width: '22.5rem',
    };
      
    const closeButtonStyle = {
        position: 'absolute',
        top: '5px',
        right: '2px',
    };

    const paragraphStyle = {
        fontSize: '14px',
      };


  return (
    <div className={`nu-turno-detail hide-scroll ${active ? 'nu-turno-detail--active':''}`} onClick={()=>{
        // setState({...state, show_turno_detail:false })
        onClose()
    }}>
            <div className='nu-turno-content hide-scroll h-100' onClick={(evt)=>{evt.stopPropagation()}}>

                <div className='d-flex'>
                    <div className='col'></div>
                    
                    <div className='nu-icon nu-icon--mid r-100 bg-darkblue m-1 cursor-pointer' onClick={()=>{
                        // setState({...state, show_turno_detail:false})
                        onClose()
                    }}>
                        <IoMdClose color='white' size={16}></IoMdClose>
                    </div>
                </div>

                <div className=''>
                        <div className='d-flex  align-items-start b-b pb-3'>
                            {/* <div className='avatar me-2'></div> */}
                            <div className='nu-icon nu-icon--mid'>
                                <RxAvatar color='#0D78BF' size={30}></RxAvatar>
                            </div>
                            <div className=''>
                                <Renderif isTrue={profesional?.name === undefined}>
                                    <div className='f-16 bold'>Sin nombre</div>
                                </Renderif>
                                <div className='f-16 bold ps-2'>{profesional?.name}</div>

                                <div className='d-flex align-items-center ps-2'>
                       
                                        <div className='f-14 line-0'>{getUserType(profesional?.idUserType.toString())}</div>
                                        <div className='d-flex align-items-center ps-1'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <IoMdStar color='#FFB054' size={20}></IoMdStar>
                                            </div>
                                            <div className='f-14 bolder line-0'>{profesional?.stars === null ? 'Sin Valoraciones' : parseFloat(profesional?.stars).toFixed(1)}</div>
                                            <div className='nu-icon nu-icon--mid' onMouseEnter={handleMouseEnter}>
                                                <IoMdInformationCircleOutline color='gray' size={18}></IoMdInformationCircleOutline>
                                            </div>
                                            {state.hoverInfo && (
                                                <div className='hover-content' style={hoverContentStyle}>
                                                    <div style={closeButtonStyle} className='nu-icon r-100 bg-darkblue m-1 cursor-pointer' onClick={handleClose}>
                                                        <IoMdClose color='white' size={16}></IoMdClose>
                                                    </div>
                                                    {/* <button onClick={handleClose} style={closeButtonStyle}>X</button> */}
                                                    <p className='pt-2' style={paragraphStyle}>Para valorar un profesional debe hacerse desde la valoración del turno.</p>
                                                </div>
                                            )}
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                </div>

                <div className='mt-3'>
                    <div className='d-flex align-items-center'>
                        <div className='f-18 bold text-darkblue'>Acogidas Completadas</div>
                        <div className='col'></div>
                        <Renderif isTrue={state.loading}>
                            <div className="spinner-border spinner--small text-darkblue"></div>
                        </Renderif>
                    </div>

                    {
                        state.receptions?.map((reception, i) => {
                            console.log(reception)
                            return (
                                <div key={i} className="d-flex b-b pb-3 mt-2">
                                    <div className='col'>
                                        <div className='f-12 bold'>{reception.center.name}</div>
                                    </div>
                                    <div className='col'>
                                        <Renderif isTrue={reception.reception.length === 0}>
                                            <div className='f-12 bold text-gray'>Sin Acogidas</div>
                                        </Renderif>
                                        {
                                            reception.reception.map((item, j)=>{
                                                if (item.idAssistanceUnit === null) return
                                              
                                                return (
                                                    <div className='d-flex align-items-center'>
                                                         <div className='nu-icon nu-icon--mid me-1'>
                                                            <HiBadgeCheck color='#16B2DE' size={18}></HiBadgeCheck>
                                                        </div>
                                                        <div className='f-12 bold text-gray' style={{flexGrow: 1}} key={j}> {getAssistanceUnit(item.idAssistanceUnit)}</div>
                                                        <div className='nu-icon nu-icon--mid me-1'>
                                                            <FaTrash color='gray' size={14}></FaTrash>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className='mt-2'>

                    <div className='f-18 bold text-darkblue'>Validar nueva Acogida</div>
                    <div>
                            <div className='mt-2'>
                                <NuSelect value={state.center_selected} onChange={(evt)=>{
                                        setState({...state, center_selected:evt.currentTarget.value})
                                    }}>
                                    <option value="">Seleccionar centro</option>
                                    {
                                        centers.map((center, i)=>{
                                        
                                            return (
                                                <option value={center.id}>{center.id}{center.name}</option>
                                            )
                                        })
                                    }
                                
                                </NuSelect>
                            </div>

                            <div className='mt-2'>
                                <NuSelect value={state.centertype_selected} onChange={(evt)=>{
                                        setState({...state, centertype_selected:evt.currentTarget.value})
                                    }}>
                                    <option value="">Seleccionar tipo de centro</option>
                                    {alphaOrder(centerType, 'description').map((item,i)=>{
                                            if(item.id==1) return;
                                    
                                            return (
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        
                                    })}
                                </NuSelect>
                            </div>

                            <Renderif isTrue={state.centertype_selected === '2'}>
                               <div className='mt-2'>
                                    <NuSelect value={state.assistenceunit_selected} onChange={(evt)=>{
                                            setState({...state, assistenceunit_selected:evt.currentTarget.value})
                                        }}>
                                        <option value="">Seleccionar Unidad Asistencial</option>
                                        { alphaOrder(assistanceUnit, 'description').map((item,i)=>{
                                            if(item.id === 1) {return}
                                            if(item.id === 14) {return}
                                            if(item.id === 21) {return}
                                            
                                            return (
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                      
                                    })}
                                    </NuSelect>
                               </div>
                            </Renderif>



                            <div className='d-flex mt-3'>

                                <div className='col'></div>

                                <div className='nu_btn nu_btn--orange' onClick={()=>{
                                    // setState({...state, show_validate:true, profesiona_selected:item})
                                    // onCompleteReception()
                                    onCompleteReception({
                                        center_selected:state.center_selected,
                                        assistenceunit_selected:state.assistenceunit_selected,
                                        centertype_selected:state.centertype_selected,
                                    })

                                    }}>

                                    <div className='nu-icon nu-icon--mid me-1'>
                                        <HiBadgeCheck size={18}></HiBadgeCheck>
                                    </div>

                                    <div className='nu_btn-text '>Validar Acogida</div>
                                </div>
                            </div>

                    </div>
                </div>


            </div>

            <div>

            </div>
            <Renderif isTrue={state.modalToAccept}>
                <div className='nu-modal'>
                    <div className='nu-modal-body' onClick={(evt)=>{evt.stopPropagation()}}>
                        <div className='d-flex'>
                            <div className='col'></div>
                            <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                                setState({...state, modalToAccept:false})
                            }}>
                                <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                            </div>
                        </div>
                        <div className='col d-flex flex-column justify-content-center align-items-center'>
                            <img width="120px" src='/assets/tick-circle.png'></img>
                            <div className='f-30 bold text-center'>Acogida validada para<br></br>usuario {profesional?.name} </div>
                            <div className='pt-2 f-14 text-center'>Al validar una acogida para este profesional, podrá visualizar turnos<br></br>de cobertura en el centro y/o unidad que hayas seleccionado.</div>
                        </div>
                        <div className='d-flex align-items-center border-bottom py-3'>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--gray' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    setState({...state, modalToAccept:false})
                                }}>
                                    <div className='nu_btn-text f-16'>Cancelar</div>
                                </div>
                            </div>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--orange' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    confirmReception({
                                        center_selected:state.center_selected,
                                        assistenceunit_selected:state.assistenceunit_selected,
                                        centertype_selected:state.centertype_selected,
                                    })
                                }}>
                                    <div className='nu_btn-text f-16'>Sí, validar Acogida</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Renderif>
            <Renderif isTrue={state.modalConfirm}>
                <div className='nu-modal'>
                    <div className='nu-modal-body'>
                        <div className='d-flex'>
                            <div className='col'></div>
                            <div className='nu-icon nu-icon--big cursor-pointer' onClick={(evt)=>{
                                evt.stopPropagation()
                                setState({...state, modalToAccept: false, modalConfirm: false})
                            }}>
                                <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                            </div>
                        </div>
                        <div className='col d-flex flex-column justify-content-center align-items-center'>
                            <img width="120px" src='/assets/tick-circle.png'></img>
                            <div className='f-30 bold text-center'>Acogida validada para<br></br>usuario {profesional?.name} </div>
                        </div>
                    </div>
                </div>
            </Renderif>
    </div>
  )
}


function NuDropDown({item, blocked ,onSelect = () => {}}) {
    
    const [state, setState] = useState({
        open:false
    })

    const _onSelect = (key) => {
        setState({...state, open:false})
        onSelect(key)
    }

    return (
        <>
        <Renderif isTrue={state.open}>
        <div className='dropdown-overlay' onClick={(evt)=>{
            
            setState({...state, open:false})
        }}></div>
        </Renderif>

        <div className="nudropdow">
            <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                setState({...state, open:!state.open})
            }}>
                <BsThreeDotsVertical size={25} color={darkBlue}></BsThreeDotsVertical>
            </div>
            <Renderif isTrue={state.open}>
                <div className='dropdow-items'>
                    <div className='dropdow-item' onClick={()=>{_onSelect(0)}}>
                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text '>Ver perfil</div>
                    </div>
                    <div className='dropdow-item' onClick={()=>{_onSelect(1)}}>
                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text '>Ver documentación</div>
                    </div>
                    <div className='dropdow-item' onClick={()=>{_onSelect(2)}}>
                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text '>Ver turnos</div>
                    </div>

                    <Renderif isTrue={blocked === true}>
                        <div className='dropdow-item' onClick={()=>{_onSelect(3)}}>
                            
                                <div className='nu-icon nu-icon--mid'>
                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                </div>

                                <div className='nu_btn-text'>Desbloquear</div>
                       
                        </div>
                    </Renderif>
                </div>
            </Renderif>
        </div>
        </>
    )
 
}

